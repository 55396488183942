import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationService } from '../shared/services/notification.service';
import { AppRoles, RolesService } from '../shared/services/roles.service';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent {
  private roleService = inject(RolesService);
  private notification = inject(NotificationService);

  async onSectionClick(url: string) {
    const hasSDDPermission = await this.roleService.hasPermission([AppRoles.SDDAnalyst, AppRoles.SDDCompliance, AppRoles.SDDBasicUser]);
    const hasCDDPermission = await this.roleService.hasPermission([AppRoles.CddAnalyst, AppRoles.CddAuditor, AppRoles.CddComplianceAnalyst, AppRoles.CddLegalCompliance, AppRoles.CddMTCreator]);

    if (hasSDDPermission || hasCDDPermission) {
      window.location.href = url;
    } else {
      this.notification.open('You do not have permission to view this section', 'Unauthorized', 5000, 'top-error-snackbar');
    }
  }
}
