<div class="home-container">
  <div class="home-inner">

    <div class="choose_text">Choose ...</div>

    <div class="boxes-wrapper">

      <div class="box" (click)="onSectionClick('/sdd/suppliers')">
        <div class="box_wrapper">
          <img src="logo.svg" alt="logo" />
          <div class="box_text">Supplier Due Diligence</div>
        </div>
      </div>

      <div class="box" (click)="onSectionClick('/cdd/customers')">
        <div class="box_wrapper">
          <img src="logo.svg" alt="logo" />
          <div class="box_text">Customer Due Diligence</div>
        </div>
      </div>

    </div>

  </div>
</div>