import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from "./layout/header/header.component";

import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { SidebarService } from './layout/sidebar/sidebar.service';
import { LoaderComponent } from "./shared/components/loader/loader.component";
import { ApplicationInsightsService } from './shared/services/application-insights.service';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, SidebarComponent, MatSidenavModule, LoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  isExpanded: boolean = true;
  isHome: boolean = false;

  constructor(private router: Router,
    private appInsightsService: ApplicationInsightsService,
    private sidebar: SidebarService) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.appInsightsService.logPageView(event.urlAfterRedirects);
        this.appInsightsService.logEvent('PageLoad', {
          pageName: event.urlAfterRedirects,
          loadTime: performance.now(),
        });

        if (event.url === '/' || event.urlAfterRedirects === '/') {
          this.isHome = true;
          this.isExpanded = false;
        } else {
          this.isHome = false;
          this.isExpanded = true;
        }
      }
    });

    this.sidebar.menu.subscribe(() => {
      this.isExpanded = !this.isExpanded;
    });
  }
}
