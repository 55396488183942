import { Component, ElementRef } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltip } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { AppRoles } from '../../shared/services/roles.service';
import { SharedModule } from '../../shared/shared.module';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterLink, RouterLinkActive, MatListModule, MatIconModule, MatTooltip, TranslateModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  isSDDActive: boolean = false;
  isCDDActive: boolean = false;
  currentYear = new Date().getFullYear();
  AppRoles = AppRoles;

  sddMenu: any[] = [
    { id: 1, name: 'sideMenu.suppliers', svgIcon: 'people_alt', link: 'sdd/suppliers', isExternal: false, roles: [AppRoles.SDDAnalyst, AppRoles.SDDCompliance, AppRoles.SDDBasicUser] },
    { id: 2, name: 'sideMenu.subjectStarsCompanies', svgIcon: 'apartment', link: 'sdd/subject-to-stars-companies', isExternal: false, showTooltip: true, roles: [AppRoles.SDDAnalyst, AppRoles.SDDCompliance, AppRoles.SDDBasicUser] },
    { id: 3, name: 'sideMenu.myRequests', svgIcon: 'drive_file_move', link: 'sdd/my-requests', isExternal: false, roles: [AppRoles.SDDAnalyst, AppRoles.SDDCompliance, AppRoles.SDDBasicUser] },
    { id: 4, name: 'sideMenu.powerBiReports', svgIcon: 'insert_chart', isExternal: true, method: 'onPowerBiReports', roles: [AppRoles.SDDAnalyst, AppRoles.SDDCompliance, AppRoles.SDDBasicUser] },
    { id: 5, name: 'sideMenu.countries', svgIcon: 'public', link: 'sdd/countries', isExternal: false, roles: [AppRoles.SDDAnalyst, AppRoles.SDDCompliance, AppRoles.SDDBasicUser] }
  ];

  cddMenuCustomers: any[] = [
    { id: 1, name: 'sideMenu.dashboard', svgIcon: 'dashboard', link: 'cdd/dashboard', isExternal: false, roles: [AppRoles.CddAnalyst, AppRoles.CddAuditor, AppRoles.CddComplianceAnalyst, AppRoles.CddLegalCompliance] },
    { id: 2, name: 'sideMenu.customers', svgIcon: 'people_alt', link: 'cdd/customers', isExternal: false, roles: [AppRoles.CddAnalyst, AppRoles.CddAuditor, AppRoles.CddComplianceAnalyst, AppRoles.CddLegalCompliance, AppRoles.CddMTCreator] },
    { id: 3, name: 'sideMenu.countries', svgIcon: 'public', link: 'cdd/countries', isExternal: false, roles: [AppRoles.CddAnalyst, AppRoles.CddAuditor, AppRoles.CddComplianceAnalyst, AppRoles.CddLegalCompliance] }
  ];

  cddMenuOperations: any[] = [
    { id: 1, name: 'sideMenu.shareholderInformation', svgIcon: 'business_center_stroke', link: 'cdd/shareholder-information', isExternal: false, showTooltip: true, roles: [AppRoles.CddAnalyst, AppRoles.CddAuditor, AppRoles.CddComplianceAnalyst, AppRoles.CddLegalCompliance] },
    { id: 2, name: 'sideMenu.managmentInformation', svgIcon: 'business_center', link: 'cdd/managment-information', isExternal: false, showTooltip: true, roles: [AppRoles.CddAnalyst, AppRoles.CddAuditor, AppRoles.CddComplianceAnalyst, AppRoles.CddLegalCompliance] },
    { id: 2, name: 'sideMenu.banks', svgIcon: 'business', link: 'cdd/banks', isExternal: false, roles: [AppRoles.CddAnalyst, AppRoles.CddAuditor, AppRoles.CddComplianceAnalyst, AppRoles.CddLegalCompliance] },
    { id: 2, name: 'sideMenu.vessels', svgIcon: 'monetization_on', link: 'cdd/vessels', isExternal: false, roles: [AppRoles.CddAnalyst, AppRoles.CddAuditor, AppRoles.CddComplianceAnalyst, AppRoles.CddLegalCompliance] },
  ];

  menus: any[] = [];

  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, private router: Router) {
    matIconRegistry.addSvgIcon('people_alt', domSanitizer.bypassSecurityTrustResourceUrl('icons/people_alt.svg'));
    matIconRegistry.addSvgIcon('apartment', domSanitizer.bypassSecurityTrustResourceUrl('icons/apartment.svg'));
    matIconRegistry.addSvgIcon('drive_file_move', domSanitizer.bypassSecurityTrustResourceUrl('icons/drive_file_move.svg'));
    matIconRegistry.addSvgIcon('insert_chart', domSanitizer.bypassSecurityTrustResourceUrl('icons/insert_chart.svg'));
    matIconRegistry.addSvgIcon('public', domSanitizer.bypassSecurityTrustResourceUrl('icons/public.svg'));
    matIconRegistry.addSvgIcon('dashboard', domSanitizer.bypassSecurityTrustResourceUrl('icons/dashboard.svg'));
    matIconRegistry.addSvgIcon('business_center_stroke', domSanitizer.bypassSecurityTrustResourceUrl('icons/business_center_stroke.svg'));
    matIconRegistry.addSvgIcon('business_center', domSanitizer.bypassSecurityTrustResourceUrl('icons/business_center.svg'));
    matIconRegistry.addSvgIcon('business', domSanitizer.bypassSecurityTrustResourceUrl('icons/business.svg'));
    matIconRegistry.addSvgIcon('monetization_on', domSanitizer.bypassSecurityTrustResourceUrl('icons/monetization_on.svg'));
    matIconRegistry.addSvgIcon('lock', domSanitizer.bypassSecurityTrustResourceUrl('icons/lock.svg'));
    matIconRegistry.addSvgIcon('highlight_off', domSanitizer.bypassSecurityTrustResourceUrl('icons/highlight_off.svg'));
    matIconRegistry.addSvgIcon('save', domSanitizer.bypassSecurityTrustResourceUrl('icons/save.svg'));
    matIconRegistry.addSvgIcon('warning', domSanitizer.bypassSecurityTrustResourceUrl('icons/warning.svg'));
    matIconRegistry.addSvgIcon('file_present', domSanitizer.bypassSecurityTrustResourceUrl('icons/file_present.svg'));
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateMenuOptions(event.urlAfterRedirects);
      }
    });
  }

  onMenuClick(menu: any) {
    if (menu.isExternal) {
      if (menu.method === 'onPowerBiReports') {
        this.onPowerBiReports();
      }
    }
  }

  isOverflowing(element: ElementRef | any, item: string): string | null {
    const el = element.nativeElement;
    return el?.scrollWidth > el?.clientWidth ? item : null;
  }

  private updateMenuOptions(url: string): void {
    if (url.includes('cdd')) {
      this.isSDDActive = false;
      this.isCDDActive = true;
    }
    if (url.includes('sdd')) {
      this.isCDDActive = false;
      this.isSDDActive = true;
    }
  }

  private onPowerBiReports() {
    window.open(environment.powerBIReports, "_blank");
  }
}
