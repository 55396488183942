<div class="sidenav-two-menus">
  <div class="sidenav-small-menu">
    <mat-nav-list class="site-menu" [ngClass]="{'cdd-nav' : !isSDDActive}"
      *appRoleBased="[AppRoles.SDDAnalyst, AppRoles.SDDCompliance, AppRoles.SDDBasicUser]">
      <mat-list-item [routerLink]="['/sdd']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
        <mat-icon matListItemIcon>group_work</mat-icon>
        <div matListItemTitle>SDD</div>
      </mat-list-item>
      <mat-list-item [routerLink]="['/cdd']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"
        *appRoleBased="[AppRoles.CddAnalyst, AppRoles.CddAuditor, AppRoles.CddComplianceAnalyst, AppRoles.CddLegalCompliance, AppRoles.CddMTCreator]">
        <mat-icon matListItemIcon>supervised_user_circle</mat-icon>
        <div matListItemTitle>CDD</div>
      </mat-list-item>
    </mat-nav-list>
  </div>
  <div class="sidenav-menu">
    <div class="sidenav-top-content">
      <ng-template [ngIf]="isSDDActive">
        <mat-nav-list class="side-navigation">
          @for (menu of sddMenu; track $index) {
          <mat-list-item [routerLink]="menu.link" routerLinkActive="active" (click)="onMenuClick(menu)"
            *appRoleBased="menu.roles">
            <mat-icon matListItemIcon [svgIcon]="menu.svgIcon"></mat-icon>
            <div matListItemTitle [matTooltip]="menu.name | translate" [matTooltipDisabled]="!menu.showTooltip">
              {{ menu.name | translate }}
            </div>
          </mat-list-item>
          }
        </mat-nav-list>
      </ng-template>
      <ng-template [ngIf]="isCDDActive">
        <ng-container
          *appRoleBased="[AppRoles.CddAnalyst, AppRoles.CddAuditor, AppRoles.CddComplianceAnalyst, AppRoles.CddLegalCompliance, AppRoles.CddMTCreator]">
          <h3>{{'sideMenu.customers' | translate}}</h3>
          <mat-nav-list class="side-navigation cdd-nav">
            @for (menu of cddMenuCustomers; track $index) {
            <mat-list-item [routerLink]="menu.link" routerLinkActive="active" (click)="onMenuClick(menu)"
              *appRoleBased="menu.roles">
              <mat-icon matListItemIcon [svgIcon]="menu.svgIcon"></mat-icon>
              <div matListItemTitle [matTooltip]="menu.name | translate" [matTooltipDisabled]="!menu.showTooltip">
                {{ menu.name | translate }}
              </div>
            </mat-list-item>
            }
          </mat-nav-list>

          <h3
            *appRoleBased="[AppRoles.CddAnalyst, AppRoles.CddAuditor, AppRoles.CddComplianceAnalyst, AppRoles.CddLegalCompliance]">
            {{'sideMenu.operations' | translate}}
          </h3>
          <mat-nav-list class="side-navigation cdd-nav">
            @for (menu of cddMenuOperations; track $index) {
            <mat-list-item [routerLink]="menu.link" routerLinkActive="active" (click)="onMenuClick(menu)"
              *appRoleBased="menu.roles">
              <mat-icon matListItemIcon [svgIcon]="menu.svgIcon"></mat-icon>
              <div matListItemTitle [matTooltip]="menu.name | translate" [matTooltipDisabled]="!menu.showTooltip">
                {{ menu.name | translate }}
              </div>
            </mat-list-item>
            }
          </mat-nav-list>
        </ng-container>
      </ng-template>
    </div>
    <div class="sidenav-bottom-content">
      <p>{{ isSDDActive ? ("appNameSDD" | translate) : ("appNameCDD" | translate) }}</p>
      <img src="logo.svg" alt="logo" />
      <p class="copyright">&copy;TENGIZCHEVROIL {{ currentYear }}</p>
    </div>
  </div>
</div>