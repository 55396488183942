<header>
  <div class="header-container" [ngClass]="{'cdd-bg' : !isSDDActive}">
    <div class="header-leftSide">
      <div class="toggleMenu">
        <button mat-button color="primary" (click)="toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div class="logo"><img src="logo.svg" alt="logo" /></div>
      <div class="site-name">
        {{ isSDDActive ? ("supplierDueDiligence" | translate) : ("customerDueDiligence" | translate) }}
      </div>
    </div>
    <div class="header-rightSide">
      <a mat-button (click)="onUserGuideLink()" class="a-link">
        <mat-icon>contact_support</mat-icon>
        {{'userGuide' | translate}}
      </a>
    </div>
  </div>
</header>