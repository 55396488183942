import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { routes } from './app.routes';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { CalAngularModule } from '@cvx/cal-angular';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { API_BASE_URL } from '../api/api-clients';
import { environment } from '../environments/environment';
import { MatPaginatorI18nService } from './mat-table-paginator-intl';
import { AppInterceptor } from './shared/interceptors/app.interceptor';
import { ApplicationInsightsService } from './shared/services/application-insights.service';

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './i18n/', '.json');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    importProvidersFrom(
      [
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: httpLoaderFactory,
            deps: [HttpClient],
          },
        }),
        CalAngularModule.forRoot(environment.calConfig)
      ]
    ),
    { provide: API_BASE_URL, useValue: environment.baseApiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: MatPaginatorI18nService },
    ApplicationInsightsService
  ]
};
