import { Routes } from '@angular/router';
import { LandingComponent } from './landing/landing.component';

export const routes: Routes = [
  { path: '', component: LandingComponent },
  {
    path: 'sdd',
    loadChildren: () => import('./modules/sdd/sdd.module').then(m => m.SddModule)
  },
  {
    path: 'cdd',
    loadChildren: () => import('./modules/cdd/cdd.module').then(m => m.CddModule)
  }
];
