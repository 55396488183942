import { Component, Inject } from '@angular/core';

import { CommonModule, DOCUMENT } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, TranslateModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  languageList = [
    { code: 'en', label: 'Eng' },
    { code: 'ru', label: 'Рус' }
  ];
  siteLanguage: string = 'Eng';
  isSDDActive: boolean = true;

  constructor(private sidebar: SidebarService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private titleService: Title) {
    this.translate.addLangs(['en', 'ru']);
    if (localStorage.getItem('language')) {
      const localeCode = localStorage.getItem('language');
      const selectedLanguage = this.languageList.find((language: any) => language.code === localeCode)?.label;
      if (localeCode && selectedLanguage) {
        this.translate.use(localeCode);
        this.translate.setDefaultLang(localeCode);
        this.document.documentElement.lang = localeCode;
        this.siteLanguage = selectedLanguage;
      }
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      this.changeSiteLanguage('en');
      this.siteLanguage = 'Eng';
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects.includes('cdd')) {
          this.isSDDActive = false;
          this.titleService.setTitle('TCO Customer Due Diligence');
        } else if (event.urlAfterRedirects.includes('sdd')) {
          this.isSDDActive = true;
          this.titleService.setTitle('TCO Supplier Due Diligence');
        } else {
          this.titleService.setTitle('TCO Compliance Hub');
        }
      }
    });
  }

  toggle() {
    this.sidebar.menu.next(true);
  }

  public changeSiteLanguage(localeCode: string): void {
    const selectedLanguage = this.languageList.find((language: any) => language.code === localeCode)?.label.toString();
    if (selectedLanguage) {
      this.siteLanguage = selectedLanguage;
      this.translate.use(localeCode);
      this.translate.setDefaultLang(localeCode);
      this.document.documentElement.lang = localeCode;
      localStorage.setItem("language", localeCode);
    }
  }

  onUserGuideLink() {
    if (this.isSDDActive) {
      window.open(environment.sddUserGuide, "_blank");
    } else {
      //window.open(environment.sddUserGuide, "_blank");
    }
  }
}
