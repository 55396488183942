import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { Observable, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
    constructor(private authService: CalAngularService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return this.authService.getAADToken([environment.apiUrl]).pipe(
            switchMap(token => {
                const cloned = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return next.handle(cloned);
            })
        );
    }
}
